<script>
  import { ArrowRight } from "lucide-svelte";

  import { analytics } from "$lib/analytics";
</script>

<div
  class="py-2 px-4 flex flex-col sm:flex-row justify-center bg-brand-green text-black text-sm font-medium"
>
  <div class="flex flex-col sm:flex-row sm:gap-x-4">
    <span>Check out our new GPU Glossary!</span>
    <span class="flex items-center gap-x-1">
      <a
        href="/gpu-glossary"
        on:click={() =>
          analytics.track("Announcement Banner GPU Glossary Link Clicked")}
        class="underline">Read now</a
      >
      <ArrowRight size="16" />
    </span>
  </div>
</div>
